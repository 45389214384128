<template>

  <div class="d-flex align-items-center min-vh-100">
    <CModal
            title="Atención"
            color="danger"
            :show.sync="warningModal"
    >
      {{alerta_txt}}
    </CModal>

    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Registrar</h1>
                <p class="text-muted">Crear cuenta</p>
                <CInput
                        placeholder="CURP"
                        autocomplete="curp"
                        v-model="curp"
                >

                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <p>
                  <a href="https://www.gob.mx/curp/" target="_blank">Consulte aqui su CURP</a>
                </p>

                <CInput
                  placeholder="Correo"
                  autocomplete="correo"
                  v-model="correo"
                  prepend="@"
                />
                <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="pass1"
                  v-model="pass1"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CInput
                  placeholder="Repetir contraseña"
                  type="password"
                  autocomplete="pass2"
                  v-model="pass2"
                  class="mb-4"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>


                <CButton v-if="desactivar_registro==1" color="success" @click="registrar()" block>{{txt_btn}}</CButton>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton @click="regresar_inicio()" block color="facebook">
                    Regresar al inicio
                  </CButton>
                </CCol>
                <CCol col="6">
                  <CButton @click="recuperar_fn()" block color="facebook">
                    Recuperar contraseña
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>

          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  export default {
    name: 'Register',
    data() {
      return {
        txt_btn : 'Crear cuenta',
        usuario : '',
        curp: '',
        correo: '',
        warningModal: false,
        alerta_txt: '',
        pass1: '',
        pass2: '',
        desactivar_registro:1,
      }
    },
    components: {
    },
     created(){
  this.get_aviso();
  },
    methods:{

        get_aviso(){

        this.$http.get("get_activar_aviso").then(
              response => {
              this.desactivar_registro= response.data.desactivar_registro;
              },
              error => {
                this.StepError(error);
              }
      );

    },


      regresar_inicio(){
        this.$router.push('/pages/login')
      },
      recuperar_fn(){
        this.$router.push('/pages/forgot')
      },
      validarEmail(valor) {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)){
        return true;
        } else {
         return false;
        }
      },
      registrar(){
     //   alert("a")
if(this.curpValida(this.curp)){
 // alert("valida")
  if(this.validarEmail(this.correo)){
    //  alert("valida")
    //validar contraseñas
    if(this.pass1 == this.pass2){
//alert("bien");
this.txt_btn = 'Creando....';
      this.$http.post('/registrar_candidato', {
        curp: this.curp,
        correo: this.correo,
        pass: this.pass1,
      }).then(response => {
        if(response.data.mensaje == '1'){
          localStorage.setItem('id', response.data.id);
          localStorage.setItem('pagina', 0);
          this.$router.push('/admision/inicio')
        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;
        }
        // do something
      })

    }else{
      this.alerta_txt = "La contraseña y su repetición no coindiden, verifique";
      this.warningModal = true;
    }


  }else{
//  alert("invalida")
    this.alerta_txt = "Su correo no es valido, porfavor verifique y vuelva a intentarlo";
    this.warningModal = true;
  }
}else{
//  alert("invalida")
  this.alerta_txt = "Su CURP no parece tener un formato valido, porfavor verifique y vuelva a intentarlo";
  this.warningModal = true;
}




      },
       curpValida(curp) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
            validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
              lngSuma      = 0.0,
              lngDigito    = 0.0;
      for(var i=0; i<17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - lngSuma % 10;
      if (lngDigito == 10) return 0;
      return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1]))
      return false;

    return true; //Validado
  }

    }

  }
</script>
